import React from 'react';
import Modal from '../../../../../components/modals/Modal';
import { format } from 'date-fns';


function ModalFeatureDetails({ open, feature, onClose }: Props) {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Feature Details</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className='divide-y dark:divide-gray-800 mb-8'>
                        <div className='pb-5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Name</p>
                            <p>{feature?.name}</p>
                        </div>

                        <div className='py-5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Description</p>
                            <p>{feature?.description}</p>
                        </div>

                        <div className='py-5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Status</p>
                            <span className={`inline-block font-medium text-sm uppercase ${feature?.status === 'ACTIVE' ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{feature?.status}</span>
                        </div>

                        <div className='grid grid-cols-2'>
                            {/*  <div className='py-5'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>

                            </div> */}

                            <div className='py-5'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                {feature?.createdDate && <p>{format(new Date(feature?.createdDate), 'd LLL yyyy h:mm a')}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    open: boolean;
    feature: any;
    onClose: () => void;
}


export default ModalFeatureDetails;