import Modal from '../../../../../components/modals/Modal';
import { format } from 'date-fns';
import { Button } from '../../../../../components/common';
import { useQuery } from '@tanstack/react-query';
import moduleService from '../../../../../services/api/subscriptions/modulesService';


function ModalModuleDetails({ open, module = {}, onClose }: Props) {

    const moduleQuery = useQuery({
        queryKey: ['subscriptions', 'modules', module?.id],
        queryFn: ({ }) => moduleService.getModule(module?.id),
        //initialData: { ...module },
        placeholderData: { ...module },
        enabled: open,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    return (
        <Modal open={open} onClose={onClose}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Module Details</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className='divide-y dark:divide-gray-800 mb-8'>
                        <div className='flex items-end pb-4'>
                            <div className='flex-1'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Name</p>
                                <p>{moduleQuery.data?.name}</p>
                            </div>

                            <span className={`inline-block font-medium text-sm uppercase ${moduleQuery.data?.status === 'ACTIVE' ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{moduleQuery.data?.status}</span>
                        </div>

                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Description</p>
                            <p>{moduleQuery.data?.description}</p>
                        </div>

                        {
                            moduleQuery.data?.features &&
                            <div className='py-4'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Features</p>
                                <p className='mb-3'>Module has {moduleQuery.data?.features?.length > 0 ? moduleQuery.data?.features?.length : 'no'} features</p>

                                {
                                    moduleQuery.data?.features?.length > 0 &&
                                    <div className='flex items-center flex-wrap gap-4'>
                                        {
                                            moduleQuery.data?.features?.map((item: any, itemIndex: number) =>
                                                <span key={item.id} title={item.description} className='bg-gray-100 dark:bg-white/5 text-sm text-gray-600 dark:text-gray-300 font-medium rounded-lg py-2.5 px-3.5'>
                                                    {itemIndex + 1}. {item.name}
                                                </span>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        }


                        <div className='grid grid-cols-2'>
                            {/* <div className='py-5'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                  <p>{format(new Date(feature.updatedDate), 'd LLL yyyy h:mm a')}</p> 
                            </div> */}

                            <div className='pt-4'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                {moduleQuery.data?.createdDate && <p>{format(new Date(moduleQuery.data.createdDate), 'd LLL yyyy h:mm a')}</p>}
                            </div>
                        </div>
                    </div>


                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    open: boolean;
    module: any;
    onClose: () => void;
}


export default ModalModuleDetails;