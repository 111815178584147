import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import useAlert from '../../../../../../state/hooks/useAlert';
import useToast from '../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import { Button, Pagination } from '../../../../../../components/common';
import ModuleListFilters from './ModuleListFilters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moduleService from '../../../../../../services/api/subscriptions/modulesService';
import { paginationInfo } from '../../../../../../utils/pagination';
import { format } from 'date-fns';
import ModalModuleForm from '../../modals/ModalModuleForm';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import { ListState } from '../../../../../../components/blocks';
import ModalFeatureForm from '../../modals/ModalFeatureForm';
import ModalModuleDetails from '../../modals/ModalModuleDetails';



function ModuleListPage() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    const [moduleForm, setModuleForm] = useState({ open: false, module: null });
    const [featureForm, setFeatureForm] = useState({ open: false, module: null });
    const [moduleDetails, setModuleDetails] = useState({ open: false, module: null });


    // get query params
    const query: any = {
        pager: {
            page: Number(searchParams.get('page')) || 1,
        },
        filters: {},
    };
    if (searchParams.get('name')) { query.filters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('status')) { query.filters['status'] = searchParams.get('status') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "App Modules" }));
    }, []);


    const queryKey = ['subscriptions', 'modules', query];
    const queryClient = useQueryClient();
    const modulesQuery = useQuery({
        queryKey: queryKey,
        queryFn: ({ }) => moduleService.getModules({ ...query.pager, ...query.filters }),
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const deleteMutation = useMutation({ mutationFn: (id: number) => moduleService.deleteModule(id) });
    const handleDelete = (module: any) => {
        openAlertConfirm({
            title: 'Delete Module',
            message: `Are you sure you want to delete ${module.name} module?`,
            onConfirmed: () => {
                deleteMutation.mutate(module.id, {
                    onSuccess: () => {
                        addToast({
                            text: "Module deleted successfully",
                            type: "success",
                        });
                        setSearchParams(current => ({
                            ...Object.fromEntries(current.entries()),
                            page: '1',
                        }));
                        modulesQuery.refetch();
                    },
                    onError: () => {
                        addToast({
                            text: "Module could not be deleted",
                            type: "error",
                        });
                    }
                });
            }
        })
    }


    return (
        <div>
            <div className='flex flex-row-reverse flex-wrap justify-between items-start mb-1'>
                {/* <LinkButton to="/subscriptions/modules/create" >Create Module</LinkButton> */}
                <Button type='button' onClick={() => setModuleForm({ open: true, module: null })} className='mb-4'>Create Module</Button>

                <ModuleListFilters
                    initialData={query.filters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={modulesQuery.isLoading}
                />
            </div>


            {
                !modulesQuery.isError && modulesQuery.isFetched && modulesQuery.isFetched && modulesQuery.data?.data && modulesQuery.data?.data.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Name</th>
                                    <th className='py-6 px-6'>Description</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    modulesQuery.data?.data.map((moduleItem: any) =>
                                        <tr key={moduleItem.id}>
                                            <td className='py-4 px-6'>
                                                <button type='button' onClick={() => setModuleDetails({ open: true, module: moduleItem })} className='transition-colors duration-300 hocus:text-blue'>
                                                    {moduleItem.name}
                                                </button>
                                            </td>
                                            <td className='py-4 px-6' title={moduleItem.description}>
                                                <p className='line-clamp-1'>{moduleItem.description}</p>
                                            </td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${moduleItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>
                                                    {moduleItem.status || "Inactive"}
                                                </span>
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(moduleItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setModuleDetails({ open: true, module: moduleItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View
                                                            </Menu.Item>
                                                            <Link to={`/subscriptions/modules/${moduleItem.id}`} state={{ module: moduleItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Manage Module
                                                            </Link>
                                                            {/* <Menu.Item as="button" type='button' onClick={() => setFeatureForm({ open: true, module: moduleItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Manage Module
                                                            </Menu.Item> */}
                                                            <Menu.Item as="button" type='button' onClick={() => setModuleForm({ open: true, module: moduleItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(moduleItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(modulesQuery.data.pager.pageSize, modulesQuery.data.pager.totalCount, modulesQuery.data?.pager.page)}</p>

                        <Pagination
                            currentPage={modulesQuery.data?.pager.page}
                            totalCount={modulesQuery.data?.pager.totalCount}
                            pageSize={modulesQuery.data.pager.pageSize}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }


            <ListState
                hasSearch={Object.keys(query.filters).length > 0}
                listCount={modulesQuery.data?.data.length || 0}
                totalDataCount={modulesQuery.data?.pager.total || 0}
                isLoading={modulesQuery.isLoading}
                isError={modulesQuery.isError}
                onTryAgain={() => modulesQuery.refetch()}
            />

            <ModalModuleForm
                open={moduleForm.open}
                module={moduleForm.module}
                onAdded={(data) => {
                    queryClient.invalidateQueries({ queryKey: [queryKey[0]] });
                    addToast({
                        text: `Module created successfully`,
                        type: "success"
                    });
                }}
                onUpdated={(data) => {
                    queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                        if (oldData) {
                            const index = oldData.data.findIndex((item: any) => item.id === data.id);
                            const newData = JSON.parse(JSON.stringify(oldData));
                            newData.data[index] = data;
                            return newData;
                        }
                    });

                    addToast({
                        text: `Module updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setModuleForm((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalFeatureForm
                open={featureForm.open}
                feature={null}
                module={featureForm.module}
                onAdded={(data) => {
                    addToast({
                        text: `Feature created successfully`,
                        type: "success"
                    });
                }}
                onUpdated={(data) => {
                    addToast({
                        text: `Feature updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setFeatureForm((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalModuleDetails
                open={moduleDetails.open}
                module={moduleDetails.module}
                onClose={() => setModuleDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default ModuleListPage;