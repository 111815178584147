import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import AppHeader from '../../components/layout/AppHeader';
import AppSidebar from '../../components/layout/AppSidebar';
import { RouteLinksType, routeLinks } from '../../config/routes-list/appNavLinkList';
import { logoutAction, selectCurrentUser } from '../../state/store/reducers/entities/authSlice';
import ToastContainer from '../../components/common/toast/ToastContainer';
import { getSidebar } from '../../state/store/reducers/ui/sidebarSlice';
import { alertConfirmClose, alertOkClose, selectAlertConfirm, selectAlertOk } from '../../state/store/reducers/ui/alertSlice';
import { ModalAlertConfirm, ModalAlertOk } from '../../components/modals';
import useIdleTimeout from '../../state/hooks/useIdleTimeout';
import useAutoLogout from '../../state/hooks/useAutoLogout';
import userSessionService from '../../services/local/userSessionService';
import AlertContainer from '../../components/modals/alert/AlertContainer';
import MediaViewerContainer from '../../components/modals/media-viewer/MediaViewerContainer';
import ErrorBoundary from '../../components/error/ErrorBoundary';


function Admin() {
    const dispatch = useDispatch();

    const { isOpen } = useSelector(getSidebar);
    const user: any = useSelector(selectCurrentUser);


    const doLogout = () => {
        dispatch<any>(logoutAction());
        window.location.reload();
    }

    // logout after 1 hour idle
    useAutoLogout({
        getExpiredTime: userSessionService.getExpiry,
        onUpdateExpiredTime: () => userSessionService.setExpiry(Date.now() + (3600 * 1000)), // logout after idle for 1 hour
        onLogout: doLogout
    });


    const manageRoutesVisibility = (routesLinks: RouteLinksType): RouteLinksType => {
        const routes = { ...routesLinks };
        const permissions = [...user.role.permissions];

        // root has access to everything
        if (permissions.includes('ROOT')) {
            return routes;
        }


        if (!permissions.includes('MANAGE_SCHOOLS')) {
            routes['appManagement'].items['appConfigurations'].visible = false;
        }

        if (!permissions.includes('MANAGE_FEED')) {
            routes['appManagement'].items['appFeeds'].visible = false;
        }

        if (!permissions.includes('MANAGE_SUBSCRIPTION')) {
            routes['appManagement'].items['subscriptions'].visible = false;
        }
        return routes;
    }


    return (
        <div className={`relative flex flex-col min-h-screen transition-spacing duration-300 ${isOpen ? 'lg:ml-80' : 'lg:ml-[5.4rem]'}`}>
            <AppSidebar routeLinks={manageRoutesVisibility(routeLinks)} />
            <AppHeader
                user={{
                    id: user.userId,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.fullName
                }}
                onLogout={doLogout}
            />
            <main className="flex-grow pt-4 pb-14 px-4 sm:px-6 md:px-8">
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </main>

            <ToastContainer />
            <AlertContainer />
            <MediaViewerContainer />
        </div>
    );
}


export default Admin;