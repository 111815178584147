import { useQuery } from '@tanstack/react-query';
import Modal from '../../../../../components/modals/Modal';
import { SelectInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import { useEffect, useMemo, useState } from 'react';
import moduleService from '../../../../../services/api/subscriptions/modulesService';
import { debounce } from 'lodash';
import { Switch } from '@headlessui/react';


function ModalAddModuleToPlan({ module, open, addedModules, onClose, onChange }: Props) {
    const [selectedModuleId, setSelectedModuleId] = useState<any>();
    //const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
    const [selectedFeatureIds, setSelectedFeatureIds] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedSearchTerm = useMemo(() => debounce((value) => setSearchQuery(value), 500), []);


    useEffect(() => {
        if (open && !module && selectedModuleId) {
            setSelectedModuleId(undefined);
        }

        if (open && module && selectedModuleId !== module.id) {
            setSelectedModuleId(module?.id);
            setSelectedFeatureIds(() => module?.features?.map((item: any) => item.id));
        }
    }, [open, module]);

    const modulesQuery = useQuery({
        queryKey: ['subscriptions', 'modules', { name: searchQuery }],
        queryFn: ({ }) => moduleService.getModules({ name: searchQuery }),
        //initialData: { ...module },
        placeholderData: module ? { data: [module] } : {},
        enabled: !!searchQuery,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });

    const moduleId = selectedModuleId || module?.id;

    const moduleQuery = useQuery({
        queryKey: ['subscriptions', 'modules', moduleId],
        queryFn: ({ }) => moduleService.getModule(moduleId),
        //initialData: { ...module },
        placeholderData: module ? { ...module } : undefined,
        enabled: open && !!selectedModuleId,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const handleSave = () => {
        onChange({
            ...moduleQuery.data,
            features: moduleQuery.data?.features.filter((item: any) => selectedFeatureIds.includes(item.id)),
        });

        onClose();
    }


    return (
        <Modal open={open} onClose={false ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Select Module & Features</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" >
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className="mb-8">
                        <label htmlFor="input-name" className='block text-sm mb-2'>Select Module</label>
                        <SelectInput
                            items={(modulesQuery.data?.data || []).filter((item: any) => module?.id === item.id || !addedModules.includes(item.id))}
                            labelKey='name'
                            valueKey='id'
                            searchable={true}
                            placeholder="Select module"
                            value={selectedModuleId}
                            onChange={(val: any) => setSelectedModuleId(val?.id)}
                            onSearch={(query) => debouncedSearchTerm(query)}
                            //onSearch={(query) => setSearchQuery(query)}
                            searching={modulesQuery.isFetching}
                        />

                        <p className='text-sm text-gray-500 dark:text-gray-400 mt-1.5'>Modules already added to plan will not show in search</p>
                    </div>


                    <div className='mb-10'>
                        {
                            moduleQuery.data?.features &&
                            <div className=''>
                                {
                                    moduleQuery.data?.features?.length > 0 &&
                                    <div className='divide-y divide-gray-200 dark:divide-gray-700'>
                                        {
                                            <div className='flex justify-between gap-x-6 py-4 px-3'>
                                                <span>{selectedFeatureIds.length} out of {moduleQuery.data?.features?.length} selected</span>

                                                <div className='flex items-center gap-x-2'>
                                                    <span>All</span>

                                                    <Switch
                                                        checked={moduleQuery.data?.features?.length === selectedFeatureIds.length}
                                                        onChange={(on: boolean) => {
                                                            if (on) {
                                                                setSelectedFeatureIds(() => moduleQuery.data?.features.map((item: any) => item.id));
                                                            } else {
                                                                setSelectedFeatureIds([]);
                                                            }
                                                        }}
                                                        className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                                    >
                                                        <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                                    </Switch>
                                                </div>
                                            </div>
                                        }

                                        {
                                            moduleQuery.data?.features?.map((featureItem: any, featureItemIndex: number) =>
                                                <div key={featureItem.id} className='flex justify-between gap-x-6 hover:bg-gray-50 hover:dark:bg-white/5 py-3 px-3'>
                                                    <div className=''>
                                                        <p className='font-medium line-clamp-1'>{featureItem.name}</p>
                                                        <p className='text-sm text-gray-500 dark:text-gray-400 line-clamp-1'>{featureItem.description}</p>
                                                    </div>

                                                    <Switch
                                                        checked={selectedFeatureIds.includes(featureItem.id)}
                                                        onChange={(on: boolean) => {
                                                            if (on) {
                                                                setSelectedFeatureIds(prevState => [...prevState, featureItem.id]);
                                                            } else {
                                                                setSelectedFeatureIds(prevState => prevState.filter(id => id !== featureItem.id));
                                                            }
                                                        }}
                                                        className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                                    >
                                                        <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                                    </Switch>
                                                </div>
                                            )
                                        }
                                    </div>
                                }


                                {
                                    moduleQuery.data?.features?.length === 0 &&
                                    <p className='text-gray-500 dark:text-gray-400 text-center py-8'>Module has no features added</p>
                                }
                            </div>
                        }

                        {
                            moduleQuery.isLoading &&
                            <p className='text-gray-500 dark:text-gray-400 text-center py-8'>Loading Features...</p>
                        }
                    </div>



                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                            Cancel
                        </Button>

                        <Button type='button' onClick={handleSave} className='' disabled={selectedFeatureIds.length === 0}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    open: boolean;
    module: any;
    addedModules: number[];
    onClose: () => void;
    onChange: (data: any) => void;
    //onAdded?: (data: any) => void;
    //onUpdated?: (data: any) => void;
}


export default ModalAddModuleToPlan;