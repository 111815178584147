import { useMutation } from '@tanstack/react-query';
import Modal from '../../../../../components/modals/Modal';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { FormInput, FormTextArea, RadioGroupInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import moduleService from '../../../../../services/api/subscriptions/modulesService';
import { useEffect } from 'react';


function ModalModuleForm({ open, module, onClose, onAdded, onUpdated }: Props) {
    useEffect(() => {
        if (open) {
            saveMutation.reset();
        }
    }, [open]);


    const saveMutation = useMutation({
        mutationFn: (data: any) => {
            return module ? moduleService.updateModule(module?.id, data) : moduleService.createModule(data);
        }
    });


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        saveMutation.mutate(formData, {
            onSuccess: (data) => {
                onClose();

                if (module && onUpdated) {
                    onUpdated(data);
                }

                if (!module && onAdded) {
                    onAdded(data);
                }
            },
            onError: () => {
            }
        });
    }


    return (
        <Modal open={open} onClose={false ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {module ? "Edit" : "Create"} Module
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={saveMutation.isPending}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            name: module?.name || '',
                            description: module?.description || '',
                            status: module?.status || 'ACTIVE',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    saveMutation.error &&
                                    <p className='text-red-500 mb-4' >{saveMutation.error as unknown as string}</p>
                                }


                                <div className="mb-8">
                                    <label htmlFor="input-name" className='block text-sm mb-2'>Module Name</label>
                                    <FormInput type="text" name="name" id="input-name" className="w-full" placeholder="Enter module name" />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>{errors['name'] as string}</p>}
                                </div>


                                <div className="mb-8">
                                    <label htmlFor="input-description" className='block text-sm mb-2'>Description</label>
                                    <FormTextArea name='description' id="input-description" className='w-full' placeholder="Type here" rows={4} />
                                    {touched['description'] && errors['description'] && <p className='text-sm text-red-500 mt-2'>Description is Required</p>}
                                </div>


                                <div className='mb-10'>
                                    <label htmlFor="" className='block mb-2'>Status</label>
                                    <RadioGroupInput
                                        value={values['status']}
                                        options={[
                                            { label: "Active", value: 'ACTIVE' },
                                            { label: "Inactive", value: 'INACTIVE' },
                                        ]}
                                        onChange={(value) => setFieldValue('status', value)}
                                    />
                                </div>


                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={saveMutation.isPending} className='' disabled={saveMutation.isPending}>
                                        {module ? "Save" : "Create Module"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}



interface Props {
    open: boolean;
    module: any;
    onClose: () => void;
    onAdded?: (data: any) => void;
    onUpdated?: (data: any) => void;
}


const validationSchema = yup.object().shape({
    name: yup.string().trim().matches(/^[^!@#$%^&*()]+$/, 'No special characters are allowed').required('Module name is required'),
    description: yup.string().trim().required(),
    status: yup.string().trim().required(),
});

export default ModalModuleForm;